<template>
<div>
  <b-modal
      id="DirectAcceptOfferModal"
      title="Accept your Offer of Admission"
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      :hide-header-close="isProcessing"
  >
    <template>

      <div v-if="showPaymentDelayMessage">
        <PaymentOutstandingComponent/>
      </div>
      <div v-if="this.error != null">
        <h5>Accept My Offer - Confirm</h5>
        <p class="error">Error message: <br/>
          <b>{{ this.error }}</b>
        </p>
      </div>
      <div v-show="displayOffer">
        <h5>Accept My Offer - Confirm</h5>
        I am accepting my offer of admission to the following program(s):<br/>
        <br/>
        <b-card class="mb-1" no-body >
          <ProgramDetails :offer="offer"/>
        </b-card>

        <div v-if="depositRequired" class="deposit-required">
          <p>The final step in accepting this(these) offer(s) is to pay your <b>${{ depositAmount }}</b> acceptance deposit. This non-refundable deposit will be applied to
          your tuition/fees and its required to hold onto your space in this(these) program(s). <br />

          <b-form-checkbox
              id="confirmPayment"
              v-model="confirmPayment"
              value="accepted"
              unchecked-value=""
          >
            I understand that deposits are non-refundable and non-transferrable.
          </b-form-checkbox>
          </p>

          <p>For more information regarding UBC's deposit policies, visit the links below:<br />
            <a href="https://www.calendar.ubc.ca/vancouver/index.cfm?tree=14,265,0,0#17933" target="_blank">
              Vancouver campus deposit policy
            </a>
            <br/>
            <a href="https://www.calendar.ubc.ca/okanagan/index.cfm?tree=14,338,0,0#15281" target="_blank">
              Okanagan campus deposit policy
            </a>
          </p>

          <p>Make sure to print your receipt to keep it for your records. </p>

        </div>
        <br />
      </div>
      <div v-show="showOfferAccept">
        <b>You've secured your spot at UBC!</b><br /><br />
        Thank you for choosing the {{offer.degreeProgram}} at UBC.
        <br />
      </div>
    </template>
    <template #modal-footer="acceptDeclineFooter">
      <b-button variant="outline-secondary" @click="close">Close</b-button>
      <template v-if="displayOffer && !showPaymentDelayMessage">
        <b-button v-if="depositRequired" variant="primary" @click="onClickToPayment" :disabled="!confirmPayment">To Payment</b-button>
        <b-button v-else variant="primary" @click="submitDecision" :disabled="isProcessing">
          Submit Decision<b-spinner small class="align-middle" v-if="isProcessing"/>
        </b-button>
      </template>
    </template>
  </b-modal>

  <PaymentRequestForm
      ref="acceptOfferPaymentRequestForm"
      v-if="getAcceptOfferPaymentRequestInfo"
      :paymentRequestForm="getAcceptOfferPaymentRequestInfo"
  />

</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { enums } from "./ErrorSourceMixin";
import PaymentRequestForm from './PaymentRequestForm.vue';
import ProgramDetails from "@/components/ProgramDetails.vue";
import moment from "moment";
import PaymentOutstandingComponent from "@/components/PaymentOutstandingComponent.vue";

export default {
  name: 'DirectAcceptOfferModal',
  mixins: [enums],
  components: {
    PaymentOutstandingComponent,
    PaymentRequestForm,
    ProgramDetails,
  },
  computed: {
    ...mapGetters("applicant", ['showOfferPostPayment', 'selectedIntentionGroup', 'acceptedOfferFeesPaymentResponseDelay']),
    ...mapGetters("payment", ['getAcceptOfferPaymentRequestInfo', 'isLoading']),
    offer(){
      if (this.selectedIntentionGroup) {
        return this.selectedIntentionGroup[0];
      }
      return {degreeProgram: null, campus: null, offerDeadline: null};
    },
    programDescription() {
      return this.offer.description || this.offer.categoryDescription || "Unclassified";
    },
    showPaymentDelayMessage() {
      if (this.selectedIntentionGroup !== null) {
        for (const item of this.selectedIntentionGroup) {
          let id = item.id;
          if (this.acceptedOfferFeesPaymentResponseDelay.indexOf(id) !== -1){
            return true;
          }
        }
      }
      return false;
    },
  },
  data() {
    return {
      isProcessing: false,
      displayOffer: true,
      showOfferAccept: false,
      error: null,
      depositRequired: false,
      depositAmount: null,
      confirmPayment: false,
    }
  },
  methods: {
    close() {
      this.$store.dispatch("applicant/setSelectedIntentionGroup", null);
      this.showOfferAccept = false;
      this.displayOffer = true;
      this.error = null;
      this.depositRequired = false;
      this.confirmPayment = false;
      this.$bvModal.hide("DirectAcceptOfferModal");
    },
    async submitDecision() {
      let offerStatus = [{id: this.offer.id, admissionOfferCode: "ACPT"}];
      this.isProcessing = true;
      // Persist the statuses to the API
      await this.$store.dispatch('applicant/getUpdateOfferStatusesPromise', {
        offerStatuses: offerStatus,
        }).then(() => {
          this.displayOffer = false;
          this.showOfferAccept = true;
          // reload intentions
          this.$store.dispatch("applicant/loadIntentions");
        }).catch((err) => {
          this.isProcessing = false;
          this.error = err.message;
        });
      this.isProcessing = false;
    },
    onClickToPayment() {
      let offerStatus = [{id: this.offer.id, admissionOfferCode: "ACPT"}];
      this.$store
          .dispatch("payment/acceptOrDeclineOffers", {
            offerStatuses: offerStatus,
          })
          .then(() => {
            if(this.getAcceptOfferPaymentRequestInfo?.paymentReqNo) {
              this.$refs.acceptOfferPaymentRequestForm.submit();
              this.close();
            }
          })
          .catch((error) => {
          });
    },
    formattedDate(d) {
      if( d )
        return moment(String(d)).format('MMMM DD, YYYY');
      return "Not Specified";
    },
  },
  watch: {
    selectedIntentionGroup: function () {
      if (this.selectedIntentionGroup) {
        this.depositAmount = this.selectedIntentionGroup[0].depositOwing;
        this.depositRequired = this.depositAmount > 0;
      }else{
        this.depositRequired = false;
      }
    },
  },
}
</script>
<style lang="css" scoped>
>>> .label-bold {
  font-weight:bold;
}

>>> .offer-detail{
  border:1px solid black;
  padding: 10px;
}

>>> .deposit-required{
  padding-top: 30px;
}

>>> .error{
  color: red;
}
</style>