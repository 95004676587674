<template>
<div>
  <b-modal
      id="AcceptOrDeclineOfferModal"
      title="Accept or Decline your Offer of Admission"
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      @hidden="resetModal"
      scrollable
      :hide-header-close="isProcessing"
  >

    <div v-if="showPaymentDelayMessage">
      <PaymentOutstandingComponent/>
    </div>
    <template>
      <div v-if="this.error != null">
        <p class="error">Error message: <br/>
          <b>{{ this.error }}</b>
        </p>
      </div>

      <div class="accordion" v-if="displayOffer">
        <h5>Please complete the form below to accept or decline your offer</h5>
        <b-card class="mb-1" no-body >
          <ProgramDetails :offer="offer"/>
          <b-card-body>
            <b-form-radio
                v-model="admissionOfferCode"
                value="ACPT"
            >
              Yes, I accept this offer.
              <template v-if="depositRequired">
                <br/>In order to accept this offer you will be asked to pay a non-refundable deposit in the amount of ${{ depositAmount }}.
              </template>
            </b-form-radio>
            <b-form-radio
                v-model="admissionOfferCode"
                value="DECL"
            >
              No, I do not accept this offer.
            </b-form-radio>
          </b-card-body>
        </b-card>
        <br />Click the "Next" button to review summary of your decision before submitting it to UBC.
      </div>

      <div class="accordion" v-else-if="offerDeclinedConfirmation">
        <h5>Decline My Offer - Confirm</h5>
        I am declining my offer of admission to the following program(s). <br /><br />
        <b-card class="mb-1" no-body >
          <ProgramDetails :offer="offer"/>
        </b-card>
        <br />
      </div>

      <div class="accordion" v-else-if="offerAcceptedConfirmation">
        <h5>Accept My Offer - Confirm</h5>
        I am accepting my offer of admission to the following program(s):<br /><br />
        <b-card class="mb-1" no-body >
          <ProgramDetails :offer="offer"/>
          <b-card-body v-if="depositRequired">
            <p>The final step in accepting this(these) offer(s) is to pay your <b>${{ depositAmount }}</b> acceptance deposit. This non-refundable deposit will be applied to
              your tuition/fees and its required to hold onto your space in this(these) program(s). <br />
              <b-form-checkbox
                  id="confirmPayment"
                  v-model="confirmPayment"
                  value="accepted"
                  unchecked-value=""
              >
                I understand that deposits are non-refundable and non-transferrable.
              </b-form-checkbox>
            </p>
            <p>For more information regarding UBC's deposit policies, visit the links below:<br />
              <a href="https://www.calendar.ubc.ca/vancouver/index.cfm?tree=14,265,0,0#17933" target="_blank">
                Vancouver campus deposit policy
              </a>
              <br/>
              <a href="https://www.calendar.ubc.ca/okanagan/index.cfm?tree=14,338,0,0#15281" target="_blank">
                Okanagan campus deposit policy
              </a>
            </p>
            <p>Make sure to print your receipt to keep it for your records. </p>
          </b-card-body>
        </b-card>
        <br />
      </div>

      <div v-show="showStatusMessage">
        <div v-if="admissionOfferCode === 'ACPT'">
          <b>You've secured your spot at UBC!</b><br /><br />
          Thank you for choosing the {{offer.degreeProgram}} at UBC.
          <br />
        </div>
        <div v-else>
          <!-- todo: display message based on processing area -->
          Thank you for confirming your decision. We're sorry to hear that you won't be joining us.
        </div>
      </div>

    </template>
    <template #modal-footer="acceptDeclineFooter">
      <b-button variant="outline-secondary" @click="resetModal">Close</b-button>
      <b-button v-if="displayOffer && !showPaymentDelayMessage" variant="primary" @click="toNext" :disabled="!admissionOfferCode">
        Next
      </b-button>
      <b-button v-else-if="offerDeclinedConfirmation && !showPaymentDelayMessage" variant="primary" @click="submitDecision" :disabled="isProcessing">
        Submit Decision<b-spinner small class="align-middle" v-if="isProcessing"/>
      </b-button>
      <template v-if="offerAcceptedConfirmation && !showPaymentDelayMessage">
        <template v-if="depositRequired">
          <b-button variant="outline-secondary" @click="onChange">Change</b-button>
          <b-button variant="primary" @click="onClickToPayment" :disabled="!confirmPayment || isProcessing">
            To Payment<b-spinner small class="align-middle" v-if="isProcessing"/>
          </b-button>
        </template>
        <template v-else>
          <b-button variant="primary" @click="submitDecision" :disabled="isProcessing">
            Submit Decision<b-spinner small class="align-middle" v-if="isProcessing"/>
          </b-button>
        </template>
      </template>

    </template>
  </b-modal>

  <PaymentRequestForm
      ref="acceptOfferPaymentRequestForm"
      v-if="getAcceptOfferPaymentRequestInfo"
      :paymentRequestForm="getAcceptOfferPaymentRequestInfo"
  />

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { enums } from "./ErrorSourceMixin";
import PaymentRequestForm from './PaymentRequestForm.vue';
import ProgramDetails from "@/components/ProgramDetails.vue";
import moment from "moment";
import PaymentOutstandingComponent from "@/components/PaymentOutstandingComponent.vue";

export default {
  name: 'AcceptOrDeclineOfferModal',
  mixins: [enums],
  components: {
    PaymentOutstandingComponent,
    PaymentRequestForm,
    ProgramDetails,
  },
  computed: {
    ...mapGetters("applicant", ['showOfferPostPayment', 'selectedIntentionGroup', 'acceptedOfferFeesPaymentResponseDelay']),
    ...mapGetters("payment", ['getAcceptOfferPaymentRequestInfo', 'isLoading']),
    offer(){
      if (this.selectedIntentionGroup) {
        return this.selectedIntentionGroup[0];
      }
      return {degreeProgram: null, campus: null, offerDeadline: null};
    },
    programDescription() {
      return this.offer.description || this.offer.categoryDescription || "Unclassified";
    },
    showPaymentDelayMessage() {
      if (this.selectedIntentionGroup !== null) {
        for (const item of this.selectedIntentionGroup) {
          let id = item.id;
          if (this.acceptedOfferFeesPaymentResponseDelay.indexOf(id) !== -1){
            return true;
          }
        }
      }
      return false;
    },
  },
  data() {
    return {
      isProcessing: false,
      displayOffer: true,
      error: null,
      depositRequired: false,
      depositAmount: null,
      confirmPayment: false,
      admissionOfferCode: null,
      offerDeclinedConfirmation: false,
      offerAcceptedConfirmation: false,
      showStatusMessage: false,
    }
  },
  methods: {
    resetModal(){
      this.$store.dispatch("applicant/setSelectedIntentionGroup", null);
      this.displayOffer = true;
      this.error = null;
      this.depositRequired = false;
      this.confirmPayment = false;
      this.admissionOfferCode = false;
      this.offerDeclinedConfirmation = false;
      this.offerAcceptedConfirmation = false;
      this.showStatusMessage = false;
      this.$bvModal.hide("AcceptOrDeclineOfferModal");
      this.$store.commit('applicant/setOnReloadIntentionsCloseModal', true);
    },
    async submitDecision() {
      let offerStatus = [{id: this.offer.id, admissionOfferCode: this.admissionOfferCode}];
      this.isProcessing = true;
      // Persist the statuses to the API
      await this.$store.dispatch('applicant/getUpdateOfferStatusesPromise', {
        offerStatuses: offerStatus,
        }).then(() => {
          this.displayOffer = false;
          this.offerDeclinedConfirmation = false;
          this.offerAcceptedConfirmation = false;
          this.showStatusMessage = true;
          this.$store.commit('applicant/setOnReloadIntentionsCloseModal', false);
          // todo: fetch message by Proc-area and admissionOfferCode : ISS-5940
          // reload intentions
          this.$store.dispatch("applicant/loadIntentions");
        }).catch((err) => {
          this.isProcessing = false;
          this.error = err.message;
        });
      this.isProcessing = false;
    },
    onClickToPayment() {
      let offerStatus = [{id: this.offer.id, admissionOfferCode: "ACPT"}];
      this.isProcessing = true;
      this.$store
          .dispatch("payment/acceptOrDeclineOffers", {
            offerStatuses: offerStatus,
          })
          .then(() => {
            if(this.getAcceptOfferPaymentRequestInfo?.paymentReqNo) {
              this.$refs.acceptOfferPaymentRequestForm.submit();
              this.close();
            }
          })
          .catch((error) => {
            this.isProcessing = false;
          });
    },
    formattedDate(d) {
      if( d )
        return moment(String(d)).format('MMMM DD, YYYY');
      return "Not Specified";
    },
    toNext(){
      if (this.admissionOfferCode === 'DECL') {
        this.offerDeclinedConfirmation = true;
        this.displayOffer = false;
      } else if(this.admissionOfferCode === 'ACPT'){
        this.offerAcceptedConfirmation = true;
        this.displayOffer = false;
      }
    },
    onChange(){
      this.offerAcceptedConfirmation = false;
      this.confirmPayment = false;
      this.displayOffer = true;
    },
  },
  watch: {
    selectedIntentionGroup: function () {
      if (this.selectedIntentionGroup) {
        this.depositAmount = this.selectedIntentionGroup[0].depositOwing;
        this.depositRequired = this.depositAmount > 0;
      }else{
        this.depositRequired = false;
      }
    },
  },
}
</script>
<style lang="css" scoped>
>>> .label-bold {
  font-weight:bold;
}

>>> .offer-detail{
  border:1px solid black;
  padding: 10px;
}

>>> .deposit-required{
  padding-top: 30px;
}

>>> .error{
  color: red;
}

>>> .instruction{
  padding-top: 30px;
  padding-top: 15px;
}
</style>