<template>
  <div>
    <div v-if="submitted">
      <div>Thank you for completing the Personal Profile for {{ programNames }}.</div>
    </div>
    <div v-else-if="isPersonalProfilePaymentResponseDelay">
      <PaymentOutstandingComponent />
    </div>
    <div v-else-if="displayPayment">
      <b-table
          striped
          bordered
          hover
          :items="feeItems"
          :fields="feeFields"
      >
      </b-table>
      <table class="fee_total">
        <tr>
          <td><b>Total:</b></td>
          <td>{{ totalAmountDue }}</td>
        </tr>
      </table>
    </div>
    <div v-else>
      <div v-if="isLoading" class="text-center">
        <b-spinner></b-spinner>
        <h5>Loading...</h5>
      </div>
      <div v-if="loadingError && !isLoading" class="text-center">
        <b-icon-exclamation-circle variant="danger"></b-icon-exclamation-circle>
        <h5>Failed to load personal profile</h5>
      </div>
      <div v-if="!isLoading && !loadingError">
        <ValidationObserver ref="formValidator" v-slot="{ dirty }">
          <b-form>
            <page-load-overlay :show="showOverlay">
            <div v-if="deadline != null">
              <div style="margin-bottom:20px;" class="extended-deadline">Deadline - {{formattedDate(deadline)}}</div>
            </div>
            <h6>Overview</h6>
            <div v-html="instructions"></div>
            <div>
              <div v-for="questionGroup in mergedQuestions">
                <div v-if="questionGroup.question.type === 'ESSAY'">
                  <b-card bg-variant="light" class="question-card">
                    <template #header>
                      <div>
                        <span class="questionGroup-header"> {{questionGroup.question.title}} </span>
                        <span v-if="questionGroup.required === 1" class="questionGroup-requisite question-required">*Required</span>
                        <span v-else class="questionGroup-requisite">Optional</span>
                      </div>
                    </template>
                    <b-form-group v-for="answerField in questionGroup.question.answerFields" :key="answerField.id">
                      <template v-slot:label>
                        <div
                            v-html="questionGroup.question.question + (answerField.maxCharLimit ? ' (Maximum ' + answerField.maxCharLimit + ' characters)' : '')"></div>
                      </template>
                      <ValidationProvider :vid="getKey(questionGroup.question, 0, answerField)"
                                          :rules="validationRule(answerField, questionGroup)"
                                          v-slot="{ errors }">
                        <b-form-textarea :id="getKey(questionGroup.question, 0, answerField)"
                                         v-if="answerField.type === 'TEXTAREA'"
                                         v-model="responses[getKey(questionGroup.question, 0, answerField)]"
                                         trim
                                         :disabled="isCommonPPQuestionReceived(questionGroup) || isReadOnly"
                                         rows="3"
                                         max-rows="8"
                                         :state="errors[0] ? false : null"></b-form-textarea>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                  </b-card>
                </div>
                <div v-if="questionGroup.question.type === 'MULTIPLEFIELDS'">
                  <b-card bg-variant="light" class="question-card" :id="questionGroup.question.id">
                    <template #header>
                      <div>
                        <span class="questionGroup-header"> {{questionGroup.question.title}} </span>
                        <span v-if="questionGroup.required === 1" class="questionGroup-requisite question-required">*Required</span>
                        <span v-else class="questionGroup-requisite">Optional</span>
                      </div>
                    </template>
                    <b-form-group>
                      <template v-slot:label>
                        <div v-html="questionGroup.question.question"></div>
                      </template>
                      <ValidationObserver v-slot="{ failed }">
                      <b-tabs card>
                        <template
                            v-for="(n, i) in parseInt(questionGroup.question.properties.REPEAT ? questionGroup.question.properties.REPEAT : 1)">
                            <b-tab :title-link-class="failed ? 'text-danger' : ''">
                              <template #title>
                                {{
                                  questionGroup.question.title + ' ' + n + (i < questionGroup.question.properties.MINREQUIRED ? '*' : '')
                                }}&nbsp;<b-icon-exclamation-circle v-if="failed"
                                                                   variant="danger"></b-icon-exclamation-circle>
                              </template>
                              <b-form-group>

                                <template v-for="answerField in questionGroup.question.answerFields">
                                  <b-form-group
                                      :label="answerField.description + (i < questionGroup.question.properties.MINREQUIRED ? '*' : '')"
                                  >
                                    <ValidationProvider :vid="getKey(questionGroup.question, i, answerField)"
                                                        :rules="validationRule(answerField, questionGroup, i)"
                                                        v-slot="{ errors }"
                                                        :mode="validationMode">
                                      <b-form-select :id="getKey(questionGroup.question, i, answerField)"
                                                     v-if="answerField.type === 'DROPDOWN'"
                                                     :options="getActivityDropDownOptions(answerField.dataElements, getKey(questionGroup.question, i, answerField) )"
                                                     value-field="id"
                                                     text-field="value"
                                                     :disabled="isCommonPPQuestionReceived(questionGroup) || isReadOnly"
                                                     v-model="responses[getKey(questionGroup.question, i, answerField)]"
                                                     :state="errors[0] ? false : null">
                                      </b-form-select>
                                      <b-form-datepicker :id="getKey(questionGroup.question, i, answerField)"
                                                         :name="getKey(questionGroup.question, i, answerField)"
                                                         v-if="answerField.type === 'DATE'"
                                                         :disabled="isCommonPPQuestionReceived(questionGroup) || isReadOnly"
                                                         v-model="responses[getKey(questionGroup.question, i, answerField)]"
                                                         :state="errors[0] ? false : null"
                                                         reset-button></b-form-datepicker>
                                      <b-form-datepicker
                                          v-if="answerField.type === 'ENDDATE'"
                                          :name="getKey(questionGroup.question, i, answerField)"
                                          v-model="responses[getKey(questionGroup.question, i, answerField)]"
                                          :disabled="isCommonPPQuestionReceived(questionGroup) || responses[getKey(questionGroup.question, i, answerField)] === 'ongoing' || isReadOnly"
                                          :state="errors[0] ? false : null"
                                          reset-button
                                      ></b-form-datepicker>
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </ValidationProvider>
                                    <ValidationProvider v-if="answerField.type === 'ENDDATE'"
                                                        :vid="getKey(questionGroup.question, i, answerField)"
                                                        :rules="validationRule(answerField, questionGroup, i)"
                                                        v-slot="{ errors }"
                                                        :mode="validationMode">
                                      <b-form-checkbox
                                          :id="getKey(questionGroup.question, i, answerField)"
                                          v-model="responses[getKey(questionGroup.question, i, answerField)]"
                                          value="ongoing"
                                          :disabled="isCommonPPQuestionReceived(questionGroup) || isReadOnly"
                                          unchecked-value=""
                                          :state="errors[0] ? false : null"
                                      >
                                        Ongoing
                                      </b-form-checkbox>
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </ValidationProvider>
                                    <ValidationProvider v-if="answerField.type === 'TEXTAREA'"
                                                        :vid="getKey(questionGroup.question, i, answerField)"
                                                        :rules="validationRule(answerField, questionGroup, i)"
                                                        v-slot="{ errors }"
                                                        :mode="validationMode">
                                      <b-form-textarea
                                          :id="getKey(questionGroup.question, i, answerField)"
                                          v-model="responses[getKey(questionGroup.question, i, answerField)]"
                                          trim
                                          rows="3"
                                          :disabled="isCommonPPQuestionReceived(questionGroup) || isReadOnly"
                                          max-rows="8"
                                          :state="errors[0] ? false : null"
                                      >
                                      </b-form-textarea>
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </ValidationProvider>
                                    <ValidationProvider v-if="answerField.type === 'TEXTFIELD'"
                                                        :vid="getKey(questionGroup.question, i, answerField)"
                                                        :rules="validationRule(answerField, questionGroup, i)"
                                                        v-slot="{ errors }">
                                      <b-form-input :id="getKey(questionGroup.question, i, answerField)"
                                                    :disabled="isCommonPPQuestionReceived(questionGroup) || isReadOnly"
                                                    v-model="responses[getKey(questionGroup.question, i, answerField)]"
                                                    :state="errors[0] ? false : null"></b-form-input>
                                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </ValidationProvider>

                                  </b-form-group>
                                </template>

                              </b-form-group>
                            </b-tab>
                          </template>
                        </b-tabs>

                        <div v-if="questionGroup.question.child">
                          <div v-for="childAnswerField in questionGroup.question.child.answerFields">

                            <div
                                v-html="questionGroup.question.child.question + (childAnswerField.maxCharLimit ? ' (Maximum ' + childAnswerField.maxCharLimit + ' characters)' : '')"></div>

                            <ValidationProvider :vid="getKey(questionGroup.question.child, 0, childAnswerField)"
                                                :rules="validationRule(childAnswerField, questionGroup.question.child, i)"
                                                v-slot="{ errors }">
                              <b-form-textarea :id="getKey(questionGroup.question.child, 0, childAnswerField)"
                                               v-if="childAnswerField.type === 'TEXTAREA'"
                                               v-model="responses[getKey(questionGroup.question.child, 0, childAnswerField)]"
                                               trim
                                               :disabled="isCommonPPQuestionReceived(questionGroup) || isReadOnly"
                                               rows="3"
                                               max-rows="8"
                                               :state="errors[0] ? false : null"></b-form-textarea>
                              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                        </div>
                      </ValidationObserver>
                    </b-form-group>
                  </b-card>
                </div>
                <div v-if="questionGroup.question.type === 'TABLE'">
                  <b-card bg-variant="light" class="question-card">
                    <template #header>
                      <div>
                        <span class="questionGroup-header"> {{questionGroup.question.title}} </span>
                        <span v-if="questionGroup.required === 1" class="questionGroup-requisite question-required">*Required</span>
                        <span v-else class="questionGroup-requisite">Optional</span>
                      </div>
                    </template>
                    <b-form-group
                        :label="questionGroup.question.question"
                    >
                      <template
                          v-for="(n, i) in parseInt(questionGroup.question.properties.REPEAT ? questionGroup.question.properties.REPEAT : 1)">
                        <b-form-group>
                          <label class="question-label">{{ questionGroup.question.title + ' ' + n }}</label>
                          <div v-for="answerField in questionGroup.question.answerFields">
                            <b-form-group
                                :label="answerField.description + (i < questionGroup.question.properties.MINREQUIRED ? '*' : '')"
                            >
                              <ValidationProvider :vid="getKey(questionGroup.question, i, answerField)"
                                                  :rules="validationRule(answerField, questionGroup)"
                                                  v-slot="{ errors }">
                                <b-form-input :id="getKey(questionGroup.question, i, answerField)"
                                              v-if="answerField.type === 'TEXTFIELD'"
                                              v-model="responses[getKey(questionGroup.question, i, answerField)]"
                                              :disabled="isCommonPPQuestionReceived(questionGroup) || isReadOnly"
                                              :state="isCommonPPQuestionReceived(questionGroup) ? null : errors[0] ? false : null"></b-form-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-form-group>
                          </div>
                        </b-form-group>
                      </template>
                    </b-form-group>
                  </b-card>
                </div>
              </div>
              <b-card bg-variant="light" class="question-card">
                <ValidationProvider vid="confirmOwnWorkCheck" :rules="{ required: { allowFalse: false } }"
                                    v-slot="{ errors }">
                  <b-form-checkbox
                      id="confirmOwnWorkCheck"
                      v-model="confirmOwnWork"
                      :value="true"
                      :disabled="isReadOnly"
                      unchecked-value=""
                      :state="errors[0] ? false : null"
                  >
                    I confirm the responses represent my own original and authentic written work.
                  </b-form-checkbox>
                </ValidationProvider>
              </b-card>
            </div>
            </page-load-overlay>
          </b-form>
        </ValidationObserver>
      </div>
    </div>
    <b-modal ref="profileUnsavedExitModal"
             ok-title="Exit"
             ok-variant="danger"
             cancel-variant="outline-secondary"
             @ok="closeAndReset"
             no-close-on-backdrop
             no-close-on-esc
             hide-header>
      There are unsaved changes. Are you sure you want to exit?
    </b-modal>
    <b-modal ref="profileSubmitModal"
             @ok="onConfirmSubmit"
             no-close-on-backdrop
             no-close-on-esc
             hide-header>
      Your answers to the Personal Profile questions cannot be changed after you submit. Are you ready to submit?
    </b-modal>
    <b-modal ref="saveErrorModal"
             no-close-on-backdrop
             no-close-on-esc
             cancel-disabled
             hide-header>
      There was an error saving the Personal Profile. Please try again later.
    </b-modal>

    <PaymentRequestForm
        ref="paymentRequestForm"
        v-if="getPersonalProfileFeeRequestInfo"
        :paymentRequestForm="getPersonalProfileFeeRequestInfo"
    />

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import Vue from "vue";
import PageLoadOverlay from './PageLoadOverlay.vue';
import PersonalProfileData from "@/api/PersonalProfileData";
import AdmissionsConstants from "@/api/AdmissionsConstants";
import PaymentRequestForm from './PaymentRequestForm.vue';
import moment from "moment";
import PaymentOutstandingComponent from "@/components/PaymentOutstandingComponent.vue";

export default {
  name: 'PersonalProfileForm',
  components: {
    PaymentOutstandingComponent,
    ValidationProvider, ValidationObserver, PaymentRequestForm, PageLoadOverlay
  },
  data() {
    return {
      confirmOwnWork: false,
      responses: {},
      activityDropdown: [],
      validationMode: "passive",
      submitted: false,
      selectedPersonalProfileCriteria: {},
      programNames : "",
      displayPayment: false,
    }
  },
  props: {
    isReadmission: Boolean,
    isReadOnly: Boolean,
    selectedDegreeProgramCode: null,
  },
  methods: {
    ...mapActions("personalProfile", ['load', 'save', 'submit', 'hide', 'reset']),
    isCommonPPQuestionReceived(questionGroup) {
      return this.isConsolidatedAndOneSubmitted(questionGroup.question.id) || this.isBCOMAndSubmitted(questionGroup.question.id);
    },

    isBCOMAndSubmitted(questionId) {
      return this.isBCOMQuestion(questionId) && this.isBCOMSubmitted();
    },

    isConsolidatedAndOneSubmitted(questionId) {
      return this.isConsolidatedQuestion(questionId) && this.isConsolidatedSubmitted();
    },
    isConsolidatedQuestion(questionId) {
      return this.isQuestionPartOfRubric(questionId, 'Consolidated');
    },

    isBCOMQuestion(questionId) {
      return this.isQuestionPartOfRubric(questionId, 'Sauder');
    },
    isQuestionPartOfRubric(questionId, rubricName) {
      const sauderQuestionSet = this.questionSets.find( qs => qs.name === rubricName);
      if(sauderQuestionSet) {
       return sauderQuestionSet.questions.some(qu => qu.question.id === questionId);
      } else {
        return false;
      }
    },

    isBCOMSubmitted() {
      return this.documentSummary.some(ds => ds.miscDocument === 'XCOM' && ds.isDocReceived);
    },

    isConsolidatedSubmitted() {
      return this.documentSummary.some(ds => ds.miscDocument !== 'XCOM' && this.personalProfileDocTypeCodes?.includes(ds.miscDocument) && ds.isDocReceived && ds.applicationType === 'ADMS');
    },

    getKey(question, responseOrdinal, answerField = null) {
      if (answerField === null) {
        return question.id + "::" + responseOrdinal;
      }
      let key = question.id + "::" + responseOrdinal + "::" + answerField.id;
      if( answerField.type === 'DROPDOWN') {
        Vue.set(this.activityDropdown, key, answerField.id);
      }
      return key;
    },
    validationRule(answerField, questionGroup = {}, ordinal = 0) {
      let result = {};
      let disableValidationIfDocReceived = this.isCommonPPQuestionReceived(questionGroup);
      if (questionGroup.question && questionGroup.question.type === "ESSAY" && questionGroup.required === 1) {
        result.required = true;
      } else if (answerField.required && answerField.required === 1) {
        const question = questionGroup.question;
        let isOrdinalRequired = question?.properties?.MINREQUIRED && ordinal < parseInt(question.properties.MINREQUIRED);
        if (question && question.type === "MULTIPLEFIELDS" && isOrdinalRequired && question.answerFields.length > 1 && !disableValidationIfDocReceived) {
          let targetIds = [];
          question.answerFields.forEach(neighbor_af => {
            if (neighbor_af.required && neighbor_af.required === 1 && neighbor_af.id !== answerField.id) {
              targetIds.push('@' + this.getKey(question, ordinal, neighbor_af));
            }
          });
          result.required_if_multiple = targetIds;

          if (isOrdinalRequired) {
            result.required = true;
          }
        } else if (question && question.type === "TABLE" && !disableValidationIfDocReceived) {
          result.required = true;
        }
      }
      if (answerField.type === "TEXTAREA" && "maxCharLimit" in answerField) {
        result.maxcharscount = {maxCharLimit: answerField.maxCharLimit};
      }
      if (answerField.type === "TEXTFIELD" && answerField.format === "EMAIL") {
        result.email = true;
      }
      if (answerField.type === "TEXTFIELD" && answerField.format === "PHONENUMBER") {
        result.phone = "^[+]?[0-9()]+[0-9\\s()/.-]*(ext)?[0-9]+$";
      }
      return result;
    },
    closeAndReset() {
      this.$refs.profileUnsavedExitModal.hide();
      this.$refs.formValidator?.reset();
      this.responses = {};
      this.reset();
    },
    async canClose(bvModalEvent) {
      if (!this.$refs.formValidator) {
        return true;
      }
      const {flags} = await this.$refs.formValidator.validateWithInfo({silent: true})
      if (flags.dirty) {
        bvModalEvent.preventDefault();
        this.$refs.profileUnsavedExitModal.show();
        return false;
      } else {
        this.closeAndReset();
        return true;
      }
    },
    isMultipleFieldsQuestion(id) {
      return this.mergedQuestions.find(questionGroup => questionGroup.question.id === id && questionGroup.question.type === "MULTIPLEFIELDS");
    },
    removeUnselectedActivities() {
      for (const key in this.activityDropdown) {
        if( this.responses[key] == '') {
          delete this.responses[key];
        }
      }
    },
    async onSave() {      
      this.removeUnselectedActivities();
      await this.save(new PersonalProfileData(false, this.procArea, this.selectedPersonalProfileCriteria.applEnteredDate(this.procArea), this.responses).toQueryParams());
      if (this.savingError) {
        this.$refs.saveErrorModal.show();
      } else {
        this.$nextTick(() => {
          if(this.$refs.formValidator) {
            this.$refs.formValidator.reset();
          }
        });
      }      
    },
    async isValid() {
      let {isValid, flags, errors} = await this.$refs.formValidator.validateWithInfo();
      if (!isValid) {
        for (let error in errors) {
          if (errors[error].length > 0) {
            const splitKey = error.split("::");
            const questionId = splitKey[0];
            let escapedId = CSS.escape(error);
            if (this.isMultipleFieldsQuestion(questionId)) {
              escapedId = CSS.escape(questionId);
            }
            const idSelector = '#' + escapedId;
            let elem = document.querySelector(idSelector);
            if (elem) {
              elem.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
              break;
            }
          }
        }
      }
      return isValid;
    },
    async onSubmit() {
      if (await this.isValid()) {
        this.$refs.profileSubmitModal.show();
      }
    },
    async onConfirmSubmit() {
      this.$emit("submitButtonBusy", true);
      this.removeUnselectedActivities();
      let intention = this.getIntentionByMiscDocType(this.selectedPersonalProfileDocType);

      this.programNames =  (this.selectedPersonalProfileDocType !== null) ? this.getSubmittedProgramName(intention) : this.getSubmittedProgramNames();

      let applEnteredDate =  (this.selectedPersonalProfileDocType !== null) ?
          this.getApplicationEnteredDate(intention) : this.selectedPersonalProfileCriteria.applEnteredDate(this.procArea);

      await this.submit(new PersonalProfileData(true, this.procArea, applEnteredDate
          , this.responses).toQueryParams(this.selectedPersonalProfileDocType));
      if (this.savingError) {
        this.$refs.saveErrorModal.show();
        this.$emit("submitButtonBusy", false);
        return false;
      } else {
        if(this.isReadmission){
          // display Personal Profile fee will be combined with re-admission fee
          this.$emit("forceToNext");
        }else {
          if (this.personalProfileFees?.toPayment) {
            this.displayPayment = true;
          } else {
            this.$store.dispatch('applicant/loadDocumentSummary'); //refresh document data so action is removed
            this.submitted = true;
            this.$emit('submittedSuccesfully');
            return true;
          }
        }
      }
    },
    async init() {
      this.validationMode = "passive";
      if(this.isReadmission){
        this.selectedPersonalProfileCriteria = this.readmissionPersonalProfileCriteria;
        await this.load(this.readmissionPersonalProfileCriteria.toADMSQueryParams());
      } else {
        this.selectedPersonalProfileCriteria = this.personalProfileCriteria;
        if (this.procArea === AdmissionsConstants.PROCESSING_AREA_ADMISSIONS) {
          await this.load(this.personalProfileCriteria.toADMSQueryParams(this.selectedPersonalProfileDocType));
        } else if (this.procArea === AdmissionsConstants.PROCESSING_AREA_EDUCATION) {
          await this.load(this.personalProfileCriteria.toEDUCQueryParams());
        }
      }

      this.mergedQuestions.forEach(group => {
        group.question.answerFields?.forEach(af => {
          const requiredAnswers = group.question.properties?.MINREQUIRED ? parseInt(group.question.properties?.MINREQUIRED) : 1;
          let savedResponses = this.answers.filter(ans => ans.question.id === group.question?.id &&
              ans.responses.find(response => response.answerField.id === af.id));
          savedResponses.sort((a, b) => a.ordinal - b.ordinal );
          const repeatAnswers = savedResponses ? savedResponses.length : requiredAnswers;
          for (let ordinal = 0; ordinal < repeatAnswers; ordinal++) {
            const key = this.getKey(group.question, ordinal, af);
            if (ordinal >= savedResponses.length) {
              Vue.set(this.responses, key, '');
            } else {
              const responseValue = savedResponses[ordinal].responses.find(response =>
                  response.answerField.id === af.id
              ).responseData;
              Vue.set(this.responses, key, responseValue);
            }
          }
          if(group.question.hasOwnProperty("child")) {
            const answers = this.answers.filter(ans => ans.question.id === group.question.child.id &&
                ans.responses.find(response => response.answerField.id === group.question.child.answerFields[0].id));
            const answer = answers[0];
            const responseValue = answer?.responses[0].responseData;
            const key = this.getKey(group.question.child, 0, answer?.responses[0].answerField);
            if( responseValue !== undefined){
              Vue.set(this.responses, key, responseValue);
            }
          }
        })
      });
      let selectedDocumentSummary = this.documentSummary.filter(
        (document) =>
          document.miscDocType === this.selectedPersonalProfileDocType
      );
      if (selectedDocumentSummary?.length > 0) {
        this.confirmOwnWork = selectedDocumentSummary[0].isDocReceived;
      }
      // enable validation after load
      this.validationMode = "aggressive";
      this.$nextTick(() => {
        this.$refs.formValidator?.reset();
      });

    },

    getActivityDropDownOptions(response, dropDownId) {
      if(this.responses[dropDownId] === undefined){
        Vue.set(this.responses, dropDownId, "" );
      }
      let emptOpt = { id: "", value: "-- Please select an option --" };
      response = [emptOpt, ...response];
      return response;
    },
    getIntentionByMiscDocType(miscDocType){
      return this.filterSubmittedPrograms().filter( document =>
          document.miscDocType === miscDocType
      );
    },
    getSubmittedProgramName(selectedIntention) {
      return selectedIntention.map( document => ( this.getDegreeDescription( document.docName.substring(0, document.docName.indexOf("Personal Profile") ))))[0];
    },
    getApplicationEnteredDate(selectedIntention){
      return this.applications.find( application => application.id === selectedIntention[0].applicationId).applEnteredDate;
    },
    getSubmittedProgramNames() {
      return this.filterSubmittedPrograms()
          .map( document => ( this.getDegreeDescription( document.docName.substring(0, document.docName.indexOf("Personal Profile") )))).join (' and ');
    },

    filterSubmittedPrograms(){
      return this.documentSummary.filter(document =>
          this.miscDocumentRules.find( item => document.docRequired === "Required"
              && document.docType === "Miscellaneous Documents"
              && !document.isDocReceived
              && document.miscDocType === item.documentType
              && item.processingArea === this.procArea ));
    },

    getDegreeDescription(degreeId) {
      return this.intentions.find(it => it.degreeId?.trim() === degreeId.trim()).description.trim();
    },
    formatAmount(a){
      return parseFloat(a).toFixed(2);
    },
    toPayment() {
      this.$store
          .dispatch("payment/personalProfileFee", {
            procArea: this.procArea,
            applEnteredDate: this.selectedPersonalProfileCriteria.applEnteredDate(this.procArea)
          })
          .then(() => {
            if(this.getPersonalProfileFeeRequestInfo?.paymentReqNo) {
              this.$refs.paymentRequestForm.submit();
              this.close();
            }
          });
    },
    formattedDate(deadline) {
      if( deadline ) {
        return moment(deadline).format('MMM DD');
      }
      return "";
    },
  },
  computed: {
    ...mapGetters(
        "personalProfile", ['isLoading', 'loadingError', 'savingError', 'isSaving', 'mergedQuestions', 'answers','questionSets', 'deadlines',
          'instructions','miscDocumentFee','personalProfileFees','isPersonalProfilePaymentResponseDelay','selectedPersonalProfileDocType']),
    ...mapGetters("applicant", ['personalProfileCriteria','applications','intentions', 'documentSummary','miscDocumentRules', 'personalProfileModalType','personalProfileDocTypeCodes']),
    ...mapGetters("reAdmission", [ 'readmissionPersonalProfileCriteria']),
    ...mapGetters("payment", ['getPersonalProfileFeeRequestInfo']),
    showOverlay() {      
      return this.isSaving;
    },
    procArea() {
      if (this.personalProfileModalType === "ADMS-PROFILE") {
        return AdmissionsConstants.PROCESSING_AREA_ADMISSIONS;
      } else if (this.personalProfileModalType === "EDUC-PROFILE") {
        return AdmissionsConstants.PROCESSING_AREA_EDUCATION;
      }
      return null;
    },
    feeFields() {
      return [
        {key: "item", label: "Item"},
        {key: "amount_due", label: "Amount Due"}
      ];
    },
    feeItems() {
      let results = [];
      this.personalProfileFees.fees.forEach((el, i) => {
        results[i] = {
          item: el.label,
          amount_due: this.formatAmount(el.amount),
        };
      });
      return results;
    },
    totalAmountDue() {
      let totalAmount = 0;
      let fees = this.personalProfileFees.fees;
      for (let i = 0; i < fees.length; i++) {
        totalAmount = totalAmount + parseFloat(fees[i].amount);
      }
      return this.formatAmount(totalAmount);
    },
    deadline() {
      const match = this.deadlines.find(d => this.procArea === d.procArea);
      return match?.deadline;
    }
  },
};
</script>
<style lang="css" scoped>
.question-card {
  margin-top: 2em;
  margin-bottom: 2em;
}

.question-label {
  font-weight: bold;
}

.questionGroup-header {
  float: left;
}

.question-required {
  color: red;
}

.questionGroup-requisite {
  float: right;
}

>>> .fee_total {
  width: 65%;
  margin-right: 0px;
  margin-left: auto;
}

>>> table.fee_total td {
  border:solid 1px;
  text-align: right;
  padding-right: 5px;
}

.extended-deadline {
  color: darkorange;
}

</style>