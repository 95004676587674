<template>
  <b-card
      :title="titleText">
    <b-card-group deck>
      <b-row>
        <b-col class="col-lg" v-for="action in actionsToDisplay" v-bind:key="action.id">
          <b-card
              align="center"
              class="action-card">
            <b-card-body>
              <p v-html="getActionName(action)" />
              <b-button v-if="action.key !== 'OFFER'"
                  class="action-card-button"
                  variant="primary"
                  @click="handleClick(action)"
              >
                {{ getActionDetail(action).title }}
              </b-button>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <ManageDocumentsModal/>
      <OUACModal/>
      <PENModal/>
      <SupplementalApplicationSummaryModal/>
      <PersonalProfileModal/>
      <PayFeesModal/>
    </b-card-group>    
   <DocumentsCard v-if="hasUndergraduateAdmissions"/>
  </b-card>
</template>
<script>
import DocumentsCard from './DocumentsCard.vue';
import {mapGetters} from "vuex";
import deriveActionItems from "@/store/modules/deriveActionItems";
import ManageDocumentsModal from "@/components/ManageDocumentsModal";
import OUACModal from "@/components/OUACModal";
import PENModal from "@/components/PENModal";
import SupplementalApplicationSummaryModal from "@/components/SupplementalApplicationSummaryModal";
import PersonalProfileModal from "@/components/PersonalProfileModal";
import PayFeesModal from "@/components/PayFeesModal";

export default {
  components: {
    DocumentsCard,
    ManageDocumentsModal,
    OUACModal,
    PENModal,
    SupplementalApplicationSummaryModal,
    PersonalProfileModal,
    PayFeesModal,
  },
  mounted() {
  },
  computed: {
    ...mapGetters("applicant", ["documentSummary", "intentions", "supplementalApplicationSummaries", "applications", "miscDocumentRules", "hasUndergraduateAdmissions", "uploadStatuses"]),
    ...mapGetters("personalProfile", ["deadlines"]),
    actionsToDisplay() {
      return deriveActionItems(this.documentSummary, this.supplementalApplicationSummaries, this.applications,
          this.intentions, this.miscDocumentRules, this.uploadStatuses, this.deadlines);
    },
  },
  data() {
    return {
      titleText: "Your next steps",
      actionDetails: new Map([
        ["GRADUATE-STUDIES-ONLINE-APPLICATION", {          
          name: "All further steps, such as accessing your offer letter, will be <br/>managed in the Graduate Studies Online Application System",
          title: "Graduate Studies Online Application",
          actionType: "graduateStudiesOnlineApplication"
        }],
        ["DOCUMENT", {
          name: "We need additional documentation",
          title: "Manage Documents",
          actionType: "manageDocuments",
          modalId: "ManageDocumentsModal"
        }],
        ["OUAC", {
          name: "Provide your Ontario Universities' Applicant Centre (OUAC) number",
          title: "Enter OUAC number",
          actionType: "ouac",
          modalId: "OUACModal"
        }],
        ["PEN", {
          name: "Provide your Personal Education Number PEN",
          title: "Enter PEN",
          actionType: "pen",
          modalId: "PENModal"
        }],
        ["SUPPLEMENTAL", {
          name: "Complete supplemental application",
          title: "Supplemental Application",
          actionType: "supplementalApplicationSummary",
          modalId: "SupplementalApplicationSummaryModal"
        }],
        ["FEES", {
          name: "Pay your application fee",
          title: "Pay Fees",
          actionType: "payFees",
          modalId: "PayFeesModal"
        }],
        ["OFFER", {
          name: "You have a pending offer",
          title: "Accept or Decline Offer",
          actionType: "acceptOffer",
          modalId: "AcceptOfferModal"
        }],
        ["ADMS-PROFILE", {
          name: "Complete your ADMS Personal Profile",
          title: "Personal Profile",
          actionType: "admsProfile",
          modalId: "PersonalProfileModal"
        }],
        ["EDUC-PROFILE", {
          name: "Complete your EDUC Personal Profile",
          title: "Personal Profile",
          actionType: "educProfile",
          modalId: "PersonalProfileModal"
        }],
      ]),
      actionItemsCustomTitle: new Array(
          "SUPPLEMENTAL"
      )
    }
  },
  methods: {
    getActionDetail(actionItem) {
      if (actionItem) {
        let actionDetail = this.actionDetails.get(actionItem.key);
        if (this.actionItemsCustomTitle.includes(actionItem.key)) {
          actionDetail.name = actionItem.name
        }
        return actionDetail;
      }
      return null;
    },
    getActionName(actionItem) {
      if (actionItem) {
        let actionDetail = this.getActionDetail(actionItem);
        if (actionDetail.name) {
          return actionDetail.name;
        }
      }
      return "";
    },
    handleCustomClickFunctionality(action) {
      if(action.key === 'SUPPLEMENTAL'){
        this.$store.dispatch("applicant/selectSupplementalSummary", action.id);
      } else if(action.key === 'EDUC-PROFILE' || action.key === 'ADMS-PROFILE') {
        this.$store.dispatch("applicant/selectPersonalProfileModalType", action.key);
      } else if(action.key === "GRADUATE-STUDIES-ONLINE-APPLICATION") {
        return this.graduateStudiesOnlineApplicationUrlAction();
      } 
    },
    handleClick(action) {
      let actionDetail = this.actionDetails.get(action.key);
      this.handleCustomClickFunctionality(action);
      this.showModal(actionDetail.modalId);
    },
    showModal(modalId){
      this.$bvModal.show(modalId);
    },    
    async graduateStudiesOnlineApplicationUrlAction() {
      await this.$store.dispatch('applicant/getExternalLinkPromise', {
        linkType: 'GRADUATE_STUDIES_ONLINE_APPLICATION'
      })
          .then((response) => {
            const url = response;
            if (url) {
              window.open(url, '_blank', '');
            }
          });
    },
  }
}
</script>
<style lang="css" scoped>

>>> .action-card {
  height: 150px;
  min-width: 225px;
  border: none;
  margin-left: 0px;
  margin-right: 0px;
}
>>> .action-card p {
  font-size: 14px;
  height: 50px;
}
>>> .action-card .card-body {
  padding: 10px;
}
>>> .action-card button {
  width: 125px;
  height: 50px;
}

.action-card-button {
  width: 110px;
  font-size: 12px;
}
</style>
