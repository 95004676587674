import api from "../../api/backend-api";

export const namespaced = true;

export const state = {
    isLoading: true,
    isSaving: false,
    loadingError: null,
    savingError: null,
    answerSet: [],
    questionSets: [],
    instructions: null,
    mergedQuestions: [],
    personalProfileFees: null,
    isPersonalProfilePaymentResponseDelay: false,
    personalProfileRubricMetadata: [],
    selectedPersonalProfileDocType: null,
    deadlines: [],
}

function mergeQuestions(questionSets = [], personalProfileRubricMetadata = []) {
    let result = [];
    let commonQuestions = [];
    let selectedRubric = getSelectedRubric(personalProfileRubricMetadata);

    if( selectedRubric) {
        questionSets = updateQuestionsSetsWithDocType(questionSets , personalProfileRubricMetadata  );
        let questionSetIdx = questionSets.findIndex(setId => {
            return setId.id === selectedRubric.id
        });

        if (questionSetIdx !== -1) {
            result = questionSets[questionSetIdx].questions;
            questionSets.splice(questionSetIdx, 1);
        }
        if( questionSets?.length > 0 ) {
            questionSets[0].questions.forEach(questionToInsert => {
                let commonQuestionIdx = result.findIndex(addedQuestion => {
                    return addedQuestion.question.id === questionToInsert.question.id
                });
                if (commonQuestionIdx !== -1) {
                    commonQuestions.push(questionToInsert);
                    result.splice(commonQuestionIdx, 1);
                }
            });
        }


    } else {
        questionSets.filter(set => set.questions).forEach(set => {
            if (result.length === 0) {
                result = result.concat(set.questions);
            } else {
                set.questions.forEach(questionToInsert => {
                    let commonQuestionIdx = result.findIndex(addedQuestion => {
                        return addedQuestion.question.id === questionToInsert.question.id
                    });
                    if (commonQuestionIdx !== -1) {
                        commonQuestions.push(result[commonQuestionIdx]);
                        result.splice(commonQuestionIdx, 1);
                    } else {
                        result.push(questionToInsert);
                    }
                });
            }
        });
    }
    return result.concat(commonQuestions);

}
function updateQuestionsSetsWithDocType(questionSets = [], personalProfileRubricMetadata = [] ){
    let updatedQuestionSets = [];
    if(personalProfileRubricMetadata) {
        let updatedQuestions = [];
        let updatedQuestionSet = null;
        personalProfileRubricMetadata.forEach( metadata => {
            updatedQuestionSet = questionSets.find( questionSet =>  metadata.id === questionSet.id );
            updatedQuestions = updatedQuestionSet.questions.filter( question => question.docType = metadata.docType  );
            updatedQuestionSet.questions = updatedQuestions;
            updatedQuestionSets.push(updatedQuestionSet);
        });
    }
    return updatedQuestionSets;
}

function getSelectedRubric(personalProfileRubricMetadata = []){
    return personalProfileRubricMetadata.find( obj => { return obj.docType === state.selectedPersonalProfileDocType });
}

export const getters = {
    isLoading(state) {
        return state.isLoading;
    },
    loadingError(state) {
        return state.loadingError;
    },
    savingError(state) {
        return state.savingError;
    },
    isSaving(state) {
        return state.isSaving;
    },
    mergedQuestions(state) {
        return state.mergedQuestions;
    },
    answers(state) {
        if (state.answerSet?.answers) {
            return state.answerSet.answers;
        }
        return [];
    },
    instructions(state) {
        return state.instructions;
    },
    questionSets(state) {
        return state.questionSets;
    },
    miscDocumentFee(state) {
        return state.miscDocumentFee;
    },
    personalProfileFees(state){
        return state.personalProfileFees;
    },
    isPersonalProfilePaymentResponseDelay(state){
        return state.isPersonalProfilePaymentResponseDelay;
    },
    personalProfileRubricMetadata(state) {
        return state.personalProfileRubricMetadata;
    },
    selectedPersonalProfileDocType(state) {
        return state.selectedPersonalProfileDocType;
    },
    deadlines(state) {
        return state.deadlines;
    }
};

export const mutations = {
    SET_PERSONAL_PROFILE(state, {answerSet, questionSets = [], instructions, miscDocumentFee,
        isPersonalProfilePaymentResponseDelay, personalProfileRubricMetadata = []}) {
        state.answerSet = answerSet;
        state.questionSets = questionSets;
        state.instructions = instructions;
        state.personalProfileRubricMetadata = personalProfileRubricMetadata;
        state.mergedQuestions = mergeQuestions(state.questionSets, state.personalProfileRubricMetadata);
        state.miscDocumentFee = miscDocumentFee;
        state.isPersonalProfilePaymentResponseDelay = isPersonalProfilePaymentResponseDelay;
    },
    SET_LOADING(state, value) {
        state.isLoading = value
    },
    SET_LOADING_ERROR(state, error) {
        state.loadingError = error
    },
    SET_SAVING_ERROR(state, error) {
        state.savingError = error
    },
    SET_SAVING(state, value) {
        state.isSaving = value
    },
    SET_INITIAL_STATE(state) {
        state.isLoading = true;
        state.isSaving = false;
        state.loadingError = null;
        state.answerSet = []
        state.questionSets = [];
        state.instructions = null;
        state.mergedQuestions = [];
        state.personalProfileFees = [];
        state.isPersonalProfilePaymentResponseDelay = false;
        state.personalProfileRubricMetadata = [];
    },
    SET_PERSONAL_PROFILE_FEES(state, value){
        state.personalProfileFees = value;
    },
    SET_SELECTED_PERSONAL_PROFILE_DOCTYPE(state, value) {
        state.selectedPersonalProfileDocType = value;
    },
    SET_DEADLINES(state, value) {
        state.deadlines = value;
    }
}

export const actions = {

    updatePersonalProfileDocType({ commit }, type) {
        commit("SET_SELECTED_PERSONAL_PROFILE_DOCTYPE", type);
    },
    updatePersonalProfileFees({ commit }, fees) {
        commit("SET_PERSONAL_PROFILE_FEES", fees);
    },
    reset({commit}) {
        commit('SET_INITIAL_STATE');
    },
    load({commit, dispatch}, personalProfileCriteria) {
        commit('SET_LOADING', true);
        commit('SET_LOADING_ERROR', null);
        return api.getPersonalProfile(personalProfileCriteria)
            .then(response => {
                commit('SET_PERSONAL_PROFILE', response.data);
            })
            .catch(error => {
                commit('SET_LOADING_ERROR', error);
            }).finally(() => {
                commit('SET_LOADING', false);
            });
    },
    save({commit, dispatch}, personalProfileForm) {
        commit('SET_SAVING', true);
        return api.savePersonalProfile(personalProfileForm)
            .then(response => {
                commit('SET_PERSONAL_PROFILE_FEES', response.data);
            })
            .catch(error => {
                commit('SET_LOADING_ERROR', error);
            }).finally(() => {
                commit('SET_SAVING', false);
            });
    },
    submit({commit, dispatch}, personalProfileForm) {
        commit('SET_SAVING', true);
        return api.savePersonalProfile(personalProfileForm)
            .then(response => {
                commit('SET_PERSONAL_PROFILE_FEES', response.data);
            })
            .catch(error => {
                commit('SET_LOADING_ERROR', error);
            }).finally(() => {
                commit('SET_SAVING', false);
            });
    },
    loadDeadlines({commit, dispatch}) {
        return api.getPersonalProfileDeadlines()
            .then(response => {
                commit('SET_DEADLINES', response.data);
            })
            .catch(error => {
                commit('SET_LOADING_ERROR', error);
            });
    }
};
