<template>
  <section>
    <div v-if="subheaderText">
      <h6>Indigenous Self-Identification</h6>
    </div>
    <div v-if="isPersonCanadianCitizen">
      <p class="text-justify">
        UBC is dedicated to supporting Indigenous student success by
        implementing and advancing Indigenous human rights. The university has
        developed a wide variety of programs and service for Indigenous
        students, including academic programs, admissions policies, housing and
        financial awards. Learn more about Indigenous student services at UBC
        here:
      </p>
      <ul>
        <li>
          UBC Vancouver:
          <a href="https://indigenous.ubc.ca/" target="_blank"
            >https://indigenous.ubc.ca/</a
          >
        </li>
        <li>
          UBC Okanagan:
          <a
            href="https://students.ok.ubc.ca/indigenous-students/"
            target="_blank"
            >https://students.ok.ubc.ca/indigenous-students/</a
          >
        </li>
      </ul>

      <p class="text-justify">
        At UBC, we are transitioning to the internationally-preferred term
        “Indigenous” to refer inclusively to status and non-status First
        Nations, Metis and Inuit peoples of Canada, in accordance with the BC
        Declaration on the Rights of Indigenous Peoples Act (DRIPA), which
        defines “Indigenous peoples” as Aboriginal peoples under section 35(2)
        of the Constitution Act, 1982. The term “Aboriginal” appears below in
        the self-identification questions to ensure consistency with the BC
        Aboriginal Data Standard. We recognize this terminology may not be
        reflective of how Indigenous individuals and communities prefer to
        identify themselves.
      </p>

      <h6>Indigenous Self-Identification Questions (Optional):</h6>

      <b-container fluid>
        <b-form-group
          label="Do you identify yourself as an Aboriginal person of Canada?"
          label-cols="6"
        >
          <b-form-radio v-model="isAboriginal" name="yes-radio" value="Yes"
            >Yes</b-form-radio
          >
          <b-form-radio v-model="isAboriginal" name="no-radio" value="No"
            >No</b-form-radio
          >
        </b-form-group>

        <b-form-group
          label="Do you identify with one or more of the following?"
          label-cols="6"
        >
          <b-form-checkbox
            id="chkFstNat"
            v-model="firstNationsStatus"
            :disabled="!isPersonAboriginal"
          >
            First Nations
          </b-form-checkbox>
          <b-form-checkbox
            id="chkInuit"
            v-model="inuitStatus"
            :disabled="!isPersonAboriginal"
          >
            Inuit
          </b-form-checkbox>
          <b-form-checkbox
            id="chkMetis"
            v-model="metisStatus"
            :disabled="!isPersonAboriginal"
          >
            Metis
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="Which community do you identify with?"
          label-cols="6"
        >
          <span class="mt-2"></span>
          <b-form-input
            class="mb-2"
            type="text"
            :disabled="!isPersonAboriginal"
            v-model="community"
          ></b-form-input>
        </b-form-group>
      </b-container>
    </div>
    <div v-else>
      <p class="text-justify">
        At UBC, we are transitioning to the internationally-preferred term
        “Indigenous” to refer inclusively to status and non-status First
        Nations, Metis and Inuit peoples of Canada, in accordance with the BC
        Declaration on the Rights of Indigenous Peoples Act (DRIPA), which
        defines “Indigenous peoples” as Aboriginal peoples under section 35(2)
        of the Constitution Act, 1982. If you have questions or need to update
        your information:
      </p>
      <p class="text-justify">
        For students registered on the Vancouver Campus, please send an email
        to: <a href="mailto:es.indigenous@ubc.ca">es.indigenous@ubc.ca</a>.
      </p>
      <p class="text-justify">
        For students registered on the Okanagan Campus, please send an email to:
        <a href="mailto:Indigenous.recruitment@ubc.ca">Indigenous.recruitment@ubc.ca</a>.
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AboriginalSelfIdentificationComponent",
  props: {
    aboriginalSubheader: Boolean,
  },
  data() {
    return {
      firstNationsStatus: false,
      inuitStatus: false,
      metisStatus: false,
      community: "",
      isAboriginal: "No",
      showModal: false,
      isCommunicating: false,
      isCanadianCitizen: false,
    };
  },
  methods: {
    setPersonAndPersonBiographic() {
      if (this.isCanadianCitizen && this.person?.personBiographic) {
        this.isAboriginal = this.person.personBiographic.aboriginal
          ? "Yes"
          : "No";
        this.firstNationsStatus = this.person.personBiographic.firstNations;
        this.community = this.person.personBiographic.firstNationsCommunity;
        this.inuitStatus = this.person.personBiographic.inuit;
        this.metisStatus = this.person.personBiographic.metis;
      }
    },

    async getDispatchPromise() {
      return await this.$store.dispatch(
        "applicant/getUpdateAboriginalSelfIdentificationPromise",
        {
          isAboriginal: this.isAboriginal === "Yes",
          firstNationStatus: this.firstNationsStatus,
          inuitStatus: this.inuitStatus,
          metisStatus: this.metisStatus,
          community: this.community,
        }
      );
    },

    onClickPrevious() {
      this.save();
      return true;
    },
    async save() {
      this.getDispatchPromise()
        .then((response) => {
          this.$store.dispatch("applicant/loadPerson");
        })
        .catch((error) => {
          console.log("Error while updating ::: " + error);
        });
    },

    formValidation() {
      return true;
    },
  },
  computed: {
    ...mapGetters("applicant", ["person"]),

    isPersonCanadianCitizen() {
      this.isCanadianCitizen =
        this.person?.personCitizenship?.countryOfCitizenship === "CANA";
      this.setPersonAndPersonBiographic();
      return this.isCanadianCitizen;
    },

    isPersonAboriginal() {
      if (this.isAboriginal === "No") {
        this.firstNationsStatus = "";
        this.inuitStatus = "";
        this.metisStatus = "";
        this.community = "";
        return false;
      }
      return true;
    },
    subheaderText() {
      return this.aboriginalSubheader;
    },
  },
};
</script>
<style lang="css" scoped>
.center-alert {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
}
</style>