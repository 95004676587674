<template>
<div>
  <b-modal
      id="SupplementalApplicationSummaryModal"
      :title="suppApp.title"
      centered
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      size="lg"
      @shown="loadSupplementalApplication"
  >
    <div v-if="displayConfirmation">
      <h4>You Have Submitted Your Supplemental!</h4>
      <p>
        Your supplemental has been successfully received. Please view your applicant status and next steps for any further required information.
      </p>
    </div>

    <div v-else-if="isSupplementalPaymentResponseDelay">
      <PaymentOutstandingComponent/>
    </div>
    <div v-else-if="displayPayment">
        <b-table
            striped
            bordered
            hover
            :items="suppAppFee"
            :fields="suppAppFeeFields"
        >
        </b-table>
         <table class="fee_total">
          <tr>
            <td><b>Total:</b></td>
            <td>{{ this.supplementalFee }}</td>
          </tr>
        </table>
    </div>
    <div v-else>
      <div v-if="loadData" class="text-center">
        <b-spinner></b-spinner>
        <h5>Loading...</h5>
      </div>
      <div v-else>
        <b-container>
          <b-row>
            <b-table :items="tableItems" :fields="tableFields" bordered></b-table>
          </b-row>
          <b-row v-if="suppApp.instructions != null"
                 class="supplemental-instructions"
                 v-html="replaceMacros(suppApp.instructions)"
          ></b-row>
        </b-container>
        <div v-for="page in suppApp.supplementalPages">
          <div class="supplemental-page-introduction" v-html="replaceMacros(page.introduction)"></div>
          <b-card
              v-for="question in removeNulls(page.supplementalQuestions)"
              :header="question.title"
              class="mb-2"
              header-tag="header"
              bg-variant="light"
              v-bind:key="'question' +  question.questionId"
          >
            <b-card-text v-html="replaceMacros(question.question)"></b-card-text>
            <b-icon v-if="question.help != null"
                    :id="'help' + question.questionId"
                    :aria-label="Help"
                    :icon="question-circle-fill">
            </b-icon>
            <b-tooltip
                v-if="question.help != null"
                :target="'help' + question.questionId"
                triggers="hover">
              {{ replaceMacros(question.help) }}
            </b-tooltip>
            <div v-for="index in question.repeat" :key="index">
              <div class="mb-2" v-for="field in question.supplementalFields">
                <span v-html="field.fieldTitle"></span>
                <span v-if="field.required === 'Y'">* </span>
                <b-icon
                    v-if="field.help != null"
                    :id="'fieldhelp' + field.questionId + field.fieldId"
                    aria-label="Help" icon="question-circle-fill"></b-icon>
                <b-tooltip
                    v-if="field.help != null"
                    :target="'fieldhelp' + field.questionId + field.fieldId"
                    triggers="hover">
                  {{ field.help }}
                </b-tooltip>

                <b-form-textarea v-if="field.fieldType === 0"
                                 :max-rows="10"
                                 :id="getResponseKey(index - 1, field.questionId, field.fieldId)"
                                 :rows="10"
                                 :disabled="isReadOnly"
                                 :maxlength=wordsToChars(field.maximumLength)
                                 v-model="v$.formResponses[getResponseKey(index - 1, field.questionId, field.fieldId)].$model"
                                 :state="validateForm(getResponseKey(index - 1, field.questionId, field.fieldId))"
                ></b-form-textarea>

                <b-form-input
                    v-else-if="field.fieldType === 1"
                    :id="getResponseKey(index - 1, field.questionId, field.fieldId)"
                    :disabled="isReadOnly"
                    v-model="v$.formResponses[getResponseKey(index - 1, field.questionId, field.fieldId)].$model"
                    :state="validateForm(getResponseKey(index - 1, field.questionId, field.fieldId))"
                ></b-form-input>

                <b-form-select
                    v-else-if="field.fieldType === 2"
                    :id="getResponseKey(index - 1, field.questionId, field.fieldId)"
                    :options="getOptions(field.supplementalDataSet.supplementalDropdown, getResponseKey(index - 1, field.questionId, field.fieldId))"
                    text-field="description"
                    value-field="entryId"
                    :disabled="isReadOnly"
                    :attribute-name="getResponseKey(index - 1, field.questionId, field.fieldId)"
                    v-model="v$.formResponses[getResponseKey(index - 1, field.questionId, field.fieldId)].$model"
                    :state="validateForm(getResponseKey(index - 1, field.questionId, field.fieldId))"
                >
                </b-form-select>

                <div v-else-if="field.fieldType === 3">
                  <b-form-radio-group
                      v-for="option in field.supplementalDataSet.supplementalDropdown"
                      :key="option.dataId-option.entryId"
                      :id="getResponseKey(index - 1, field.questionId, field.fieldId)"
                      :disabled="isReadOnly"
                      v-model="v$.formResponses[getResponseKey(index - 1, field.questionId, field.fieldId)].$model"
                      :state="validateForm(getResponseKey(index - 1, field.questionId, field.fieldId))"
                  >
                    <b-form-radio :value="option.entryId">{{ option.description }}</b-form-radio>
                    <b-form-invalid-feedback :state="validateForm(getResponseKey(index - 1, field.questionId, field.fieldId))">Please select one</b-form-invalid-feedback>
                  </b-form-radio-group>

                </div>

                <div v-else-if="field.fieldType === 4">
                  <div v-if="field.supplementalDataSet">
                    <b-form-checkbox-group
                        v-for="option in field.supplementalDataSet.supplementalDropdown"
                        :key="option.dataId-option.entryId"
                        :id="getResponseKey(index - 1, field.questionId, field.fieldId)"
                        v-model="v$.formResponses[getResponseKey(index - 1, field.questionId, field.fieldId)].$model"
                        :state="validateForm(getResponseKey(index - 1, field.questionId, field.fieldId))"
                    >
                      <b-form-checkbox :value="option.entryId">{{ option.description }}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                  <div v-else>
                    <b-form-checkbox
                        value="Y"
                        :disabled="isReadOnly"
                        :id="getResponseKey(index - 1, field.questionId, field.fieldId)"
                        v-model="v$.formResponses[getResponseKey(index - 1, field.questionId, field.fieldId)].$model"
                        :state="validateForm(getResponseKey(index - 1, field.questionId, field.fieldId))">
                    </b-form-checkbox>
                  </div>
                </div>

                <div
                    class="invalid-feedback"
                    v-for="error of getError(getResponseKey(index - 1, field.questionId, field.fieldId))"
                    :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <b-container class="supplemental-declaration">
          <b-row v-html="suppApp.declaration"></b-row>
          <b-row class="pt-2">
            <b-form-checkbox
                id="agreeToDeclaration"
                v-model="agreeToDeclaration"
                :value="true"
                :disabled="isReadOnly"
                :unchecked-value="null"
            >
              I have read and I understand this declaration
            </b-form-checkbox>
          </b-row>
        </b-container>
        <div class="pt-2">
          <b-alert :show="checkDeclaration()" variant="danger">
            You must accept the declaration to continue.
          </b-alert>
        </div>
      </div>

    </div>
    <template #modal-footer="supplementalFooter">
      <b-alert
          class="center-alert"
          variant="success"
          :show="dismissCountDown"
          fade
          @dismiss-count-down="countDownChanged">
        Saved Successfully
      </b-alert>
      <template v-if="isReadOnly || isSupplementalPaymentResponseDelay">
        <b-button variant="outline-secondary" @click="close">
          Close
        </b-button>
      </template>
      <template v-else>
        <b-button variant="outline-secondary" :disabled="isSaving || isSubmitting" @click="close">
          Close
        </b-button>
        <b-button variant="outline-secondary" v-if="!displayPayment && !displayConfirmation" :disabled="isSaving || isSubmitting" @click="saveSupplementalResponses(true)">
          <b-spinner small class="align-middle" v-if="isSaving"></b-spinner>
          <span v-else>Save</span>
        </b-button>
        <b-button variant="outline-secondary" v-if="displayPayment && !displayConfirmation" :disabled="isSaving || isSubmitting" @click="displayPayment = false;">Back</b-button>

        <b-button v-if="!owesPayment && !displayConfirmation"
                  :disabled="isSaving || isSubmitting"
                  @click="submit"
                  variant="primary">
          <b-spinner small class="align-middle" v-if="isSubmitting"></b-spinner>
          <span v-else>Submit</span>
        </b-button>

        <b-button v-if="displayPayment && owesPayment && !displayConfirmation"
                  :disabled="isSaving || isSubmitting"
                  @click="toPayment"
                  variant="primary">
          <b-spinner small class="align-middle" v-if="isSubmitting"></b-spinner>
          <span v-else>To Payment</span>
        </b-button>

        <b-button  v-if="!displayPayment && owesPayment && !displayConfirmation"
                  @click="saveSupplementalResponsesThenDisplayPayment()"
                  :disabled="isSaving"
                  variant="primary">
          Next
        </b-button>
      </template>
    </template>
  </b-modal>

  <PaymentRequestForm
      ref="paymentRequestForm"
      v-if="getSupplementalFeeRequestInfo"
      :paymentRequestForm="getSupplementalFeeRequestInfo"
  />
</div>
</template>

<script lang="ts">
import useVuelidate from "@vuelidate/core";
import {mapGetters} from "vuex";
import moment from 'moment';
import PaymentRequestForm from './PaymentRequestForm.vue';

import {required, requiredIf,} from "@vuelidate/validators";
import Vue from "vue";
import PaymentOutstandingComponent from "@/components/PaymentOutstandingComponent.vue";

export default {
  name: 'supplementalApplicationModal',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    PaymentOutstandingComponent,
    PaymentRequestForm,
  },
  data() {
    return {
      suppAppSummary: Object,
      suppApp: Object,
      suppResponses: Array,
      loadData: true,
      isSaving: false,
      isSubmitting: false,
      agreeToDeclaration: null,
      responseKeyString: "response",
      letterToWordRatio: 5.8333,
      dismissSecs: 5,
      displayPayment: false,
      displayConfirmation: false,
      dismissCountDown: 0,
      supplementalFee: 0,
      formResponses: Object,
      isSupplementalPaymentResponseDelay: false,
      defaultDeadlineHour: 23,
      defaultDeadlineMinute: 59,
    }
  },
  validations() {
    let formResponses = {};
    let agreeToDeclaration = { required };

    if(!this.suppApp.supplementalPages) {
      return { formResponses };
    }
    this.suppApp.supplementalPages.forEach(page => {
      this.removeNulls(page.supplementalQuestions).forEach(question => {
        for (let i = 0; i < question.repeat; i++) {
          question.supplementalFields.forEach(field => {
            formResponses[this.getResponseKey(i, question.questionId, field.fieldId)] = {
              required: requiredIf(function () {
                return field.required === 'Y';
              }),
            };
          });
        }
      });
    });
    return { formResponses, agreeToDeclaration };
  },
  computed: {
    ...mapGetters("applicant",['supplementalModalSummary','documentSummary']),
    ...mapGetters("payment", ['getSupplementalFeeRequestInfo']),
    owesPayment() {
      return this.supplementalFee > 0;
    },
    isReadOnly() {
        let deadline;
        let deadlineStr = this.suppAppSummary?.deadline ? this.suppAppSummary.deadline : "";
        let extDeadlineStr = this.suppAppSummary?.extendedDeadline ? this.suppAppSummary.extendedDeadline : "";
        if (extDeadlineStr !== ""){
          deadline = this.getDateAtMidnight(extDeadlineStr); // Deadline always defaults to midnight
        } else if (deadlineStr !== "") {
          deadline = this.getDateAtMidnight(deadlineStr); // Deadline always defaults to midnight
        } else {
          return false;
        }
        return moment().isAfter(deadline);
    },
    tableItems() {
      return [{
        year: this.suppAppSummary?.year ? this.suppAppSummary.year : "",
        deadline: this.suppAppSummary ? this.formatDate(this.suppAppSummary.deadline) : "",
        extension_deadline: this.suppAppSummary?.extendedDeadline ? this.formatDate(this.suppAppSummary.extendedDeadline) : "",
        requirement: this.suppAppSummary?.requirement ? this.suppAppSummary.requirement : "",
      }];
    },
    tableFields() {
      let fields = [{key: "year", label: "Year"}];
      if (this.isReadOnly){
        fields.push({key: "deadline", label: "Deadline", tdClass: "isExpired"});
        fields.push({key: "extension_deadline", label: "Extension Deadline", tdClass: "isExpired"});
      }else{
        fields.push({key: "deadline", label: "Deadline"});
        fields.push({key: "extension_deadline", label: "Extension Deadline"});
      }
      fields.push({key: "requirement", label: "Requirement"});
      return fields;
    },
    supplementalSubmitted() {
      const matchedDocument =  this.documentSummary.find(doc => doc.documentGuid === this.suppAppSummary.documentGuid);
      return matchedDocument.isDocReceived === true;
    },
    suppAppFee() {
      return [{
        due_date: this.suppAppSummary ? this.formatDate(this.suppAppSummary.deadline) : "",
        item: this.suppAppSummary?.accessDescription ? this.suppAppSummary?.accessDescription : "",
        amount_due: this.supplementalFee,
      }];
    },
    suppAppFeeFields() {
      return [
        {key: "due_date", label: "Due Date"},
        {key: "item", label: "Item"},
        {key: "amount_due", label: "Amount Due"}
      ];
    },
  },
  watch: {
    supplementalModalSummary() {
      this.suppAppSummary = this.supplementalModalSummary;
    }
  },
  methods: {
    getOptions(suppDropDownValues, dropDownId) {
      if(this.formResponses[dropDownId] === undefined){
        Vue.set(this.formResponses, dropDownId, "" );
      }
      let emptOpt = { entryId: "", description: "-- Please select an option --" };
      suppDropDownValues = [emptOpt, ...suppDropDownValues];
      return suppDropDownValues;
    },
    close() {
      this.suppApp = {};
      this.$bvModal.hide("SupplementalApplicationSummaryModal");
      this.displayConfirmation = false;
      this.displayPayment = false;
      this.loadData = true;
    },
    getDateAtMidnight(dateString) {
        return moment(dateString, 'YYYY-MM-DD')
            .set("hour", this.defaultDeadlineHour)
            .set("minute", this.defaultDeadlineMinute)
            .toDate();
    },
    checkDeclaration() {
      return this.agreeToDeclaration?.$error === true;
    },
    loadSupplementalApplication() {
      this.loadData = true;
      this.displayPayment = false;
      if(this.suppAppSummary) {
        this.$http.get("/supplemental/", {
          params: {
            "application_id": this.suppAppSummary.applicationId,
            "access_id": this.suppAppSummary.accessId,
            "document_guid": this.suppAppSummary.documentGuid
          }
        }).then(response => {
          this.suppResponses = response.data.responses;
          this.populateFormResponses(response.data.application, response.data.responses);
          this.suppApp = response.data.application;
          this.supplementalFee = response.data.supplementalFee;
          this.displayConfirmation = this.supplementalSubmitted;
          this.isSupplementalPaymentResponseDelay = response.data.isSupplementalPaymentResponseDelay;
          this.$nextTick(() => { this.v$.$reset() })
        }).finally(() => {
          this.loadData = false;
        });
      } else{
        this.loadData = false;
      }
    },
    populateFormResponses(application, responses) {
      this.formResponses = {};
      application.supplementalPages.forEach(page => {
        this.removeNulls(page.supplementalQuestions).forEach(question => {
          for (let i = 0; i < question.repeat; i++) {
            question.supplementalFields.forEach(field => {
              this.$set(this.formResponses, this.getResponseKey(i, question.questionId, field.fieldId),
                  responses[this.findResponseIndex(i, question.questionId)]['field' + field.fieldId]);
            });
          }
        });
      });
    },
    updateSupplementalResponses() {
      Object.keys(this.formResponses).forEach(function(key,index) {
        let ids = key.replace(this.responseKeyString, "").split("-");
        this.suppResponses[ids[0]]["field" + ids[1]] = this.formResponses[key];
      }.bind(this));
    },
    isFormValid() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        const escapedId = CSS.escape(this.v$.$errors[0].$property);
        const elem = document.querySelector('#' + escapedId);
        if (elem) {
          elem.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          });
        }
        return false;
      }
      return true;
    },
    validateAndSaveSupplementalResponses(displaySuccess) {
      if (!this.isFormValid()) {
        return false;
      }
      return this.saveSupplementalResponses(displaySuccess);
    },
    saveSupplementalResponsesThenDisplayPayment(){
      const saveSuccessful = this.validateAndSaveSupplementalResponses(false);
      if(saveSuccessful) {
        this.displayPayment = true;
      }
    },
    toPayment() {
      this.isSubmitting = true;
      this.$store
          .dispatch("payment/supplementalFee", {
            accessId: this.suppAppSummary.accessId,
            documentGuid:  this.suppAppSummary.documentGuid,
          })
          .then(() => {
            if(this.getSupplementalFeeRequestInfo?.paymentReqNo) {
              this.$refs.paymentRequestForm.submit();
              this.close();
            }
          })
          .catch((error) => {
            this.isSubmitting = false;
          });
    },
    async saveSupplementalResponses(displaySuccess) {
      this.updateSupplementalResponses();
      this.isSaving = true;
      let success = false;
      await this.$http.post('supplemental/'+ this.suppAppSummary.documentGuid , this.suppResponses)
          .then((response) => {
            if(displaySuccess){
              this.showAlert();
            }
            success = true;
          }).finally(() => {
            this.isSaving =  false;
      });
      return success;
    },
    submit() {
      const saveSuccessful = this.validateAndSaveSupplementalResponses(false);
      if(saveSuccessful) {
        this.isSubmitting = true;
        this.$http.post('supplemental/submit/' + this.suppAppSummary.documentGuid)
          .then((response) => {
            this.displayConfirmation = true;
            this.$store.dispatch('applicant/loadDocumentSummary'); //refresh document data so action is removed
          }).finally(() => {
            this.isSubmitting =  false;
          });
      }
    },
    removeNulls(arrayOfObjects) {
      return arrayOfObjects.filter(function (object) {
        return object !== null;
      })
    },
    findResponseIndex(repeat, questionId) {
      return this.suppResponses.findIndex(r =>  (r.repeat === repeat) && (r.questionId === questionId));
    },
    getResponseKey(index, questionId, fieldId) {
      return this.responseKeyString + this.findResponseIndex(index, questionId) + '-' + fieldId;
    },
    replaceMacros(html) {
      if(html === null){
        return null;
      }
      html = html.replaceAll("{contactemail}", this.suppAppSummary.contactEmail);
      html = html.replaceAll("{closedate}", this.formatDate(this.suppAppSummary.deadline));
      html = html.replaceAll("{opendate}", this.formatDate(this.suppAppSummary.openDate));
      return html;
    },
    formatDate(value) {
      return moment(String(value)).format('MMMM DD, YYYY')
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    validateForm(key) {
      const formField = this.v$.formResponses[key];
      if(formField?.$dirty && formField.$error) {
        return false;
      }
      return null;
    },
    getError(key) {
      return this.v$.formResponses[key].$errors;
    },
    wordsToChars(words) {
      return words * this.letterToWordRatio;
    },
  },
};
</script>
<style lang="css" scoped>

.supplemental-page-introduction {
  border: 1px solid #D8A838;;
  background-color: #FFFFE0;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
thead {
  background-color: #F7F7F7;
}
.supplemental-declaration {
  border: 1px solid red;
  background: pink;
  padding: 15px 40px;
}
.supplemental-instructions {
  border: 1px solid #D8A838;;
  background-color: #FFFFE0;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.center-alert {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
}
>>> .isExpired {
  color: #dc3545;
}

>>> .fee_total {
  width: 65%;
  margin-right: 0px;
  margin-left: auto;
}

>>> table.fee_total td {
  border:solid 1px;
  text-align: right;
  padding-right: 5px;
}

</style>
