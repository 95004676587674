import { render, staticRenderFns } from "./DirectAcceptOfferModal.vue?vue&type=template&id=61b87936&scoped=true"
import script from "./DirectAcceptOfferModal.vue?vue&type=script&lang=js"
export * from "./DirectAcceptOfferModal.vue?vue&type=script&lang=js"
import style0 from "./DirectAcceptOfferModal.vue?vue&type=style&index=0&id=61b87936&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b87936",
  null
  
)

export default component.exports