<template>
  <div>
    <b-modal
        id="PayFeesModal"
        title="Pay your application fees"
        ref="addModal"
        centered
        no-close-on-backdrop
        no-close-on-esc
        @hidden="close"
        size="xl"
    >
      <template #modal-footer="selfIdentFooter">
        <b-alert :show="isError" variant="danger">
          <li v-for="(error, idx) in errors" :key="idx">
            {{ error }}
          </li>
        </b-alert>
        <b-button v-show="applicationFeesPaymentResponseDelay" @click="close" :disabled="isLoading"
                  variant="outline-secondary">Close
        </b-button>
        <b-button v-show="!applicationFeesPaymentResponseDelay"
                  :disabled="isLoading"
                  @click="saveChanges"
                  variant="primary"
                  class="btn-size"
        >
          <b-spinner small class="align-middle" v-if="isLoading"></b-spinner>
          <span v-else>Continue</span>
        </b-button>
      </template>
      <div v-if="applicationFeesPaymentResponseDelay">
       <PaymentOutstandingComponent/>
      </div>
      <div v-else>
        <template>
          <b-table
              striped
              bordered
              hover
              :items="applicationsPastDue"
              :fields="columns"
          >
          </b-table>
          <table class="fee_total">
            <tr>
              <td><b>Total:</b></td>
              <td>{{ totalAmountDue() }}</td>
            </tr>
          </table>
        </template>
      </div>
    </b-modal>
    <PaymentRequestForm
        ref="payFeesPaymentRequestForm"
        v-if="getPayFeesPaymentRequestInfo"
        :paymentRequestForm="getPayFeesPaymentRequestInfo"
    />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import PaymentRequestForm from './PaymentRequestForm.vue';
import PaymentOutstandingComponent from "@/components/PaymentOutstandingComponent.vue";

export default {
  name: "PayFeesModal",
  components: {
    PaymentOutstandingComponent,
    PaymentRequestForm,
  },
  computed: {
    ...mapGetters("applicant", ['applications', 'intentions', 'isLoading', 'applicationFeesPaymentResponseDelay']),
    ...mapGetters("payment", ['getPayFeesPaymentRequestInfo', 'isLoading']),
    isError() {
      return this.errors?.length > 0;
    },
  },
  created() {
    let today = new Intl.DateTimeFormat("en-CA").format(new Date());
    this.applicationsPastDue = this.applications.filter(
        (app) => ((app.applEnteredDate < today) && (!app.applicationFeeMet))
    );

    this.intents = this.intentions;
  },
  data() {
    return {
      columns: [
        {
          key: "due date",
          formatter: (value, key, application) => {
            return application.applEnteredDate.substring(0, 10);
          },
          thClass: "dueDate",
        },
        {
          key: "item",
          formatter: (value, key, application) => {
            let sess = this.getSession(
                application.admId,
                application.admissProcArea,
                application.applEnteredDate.substring(0, 10)
            );
            if (application.admissProcArea == "ADMS") {
              return sess + " Undergraduate Admissions application fee";
            } else if (application.admissProcArea == "GRAD") {
              return sess + " Graduate Studies Admissions application fee";
            }
            return sess + " Admissions application fee";
          },
          thClass: "item",
        },
        {
          key: "amount_due",
          tdClass: "text-right",
          formatter: (value, key, application) => {
            return this.formatAmount(this.getApplicationAmountDue(application));
          },
        },
      ],

      error: null,
      errors: [],
      message: "",
      validationErrorMessage: "",
      description: "",
      applicationsPastDue: [],
      applicationsNotPastDue: [],
      session: "",
      intention: null,
      intents: null,
    };
  },
  methods: {
    close() {
      this.reset();
      this.$bvModal.hide("PayFeesModal");
    },
    reset() {
      this.errors = [];
      this.error = null;
      this.validationErrorMessage = "";
    },
    async saveChanges() {
      this.$store
          .dispatch("payment/payFees", {
            feesAmt: this.totalAmountDue(),
          })
          .then(() => {
            if (this.getPayFeesPaymentRequestInfo?.paymentReqNo) {
              this.$refs.payFeesPaymentRequestForm.submit();
              this.close();
            }
          })
          .catch((error) => {
            this.saveErrorMessage(error);
          });
    },
    saveErrorMessage(error) {
      this.errors = [...error.response.data.details];
    },
    closeErrorMessage() {
      this.error = null;
      this.close();
    },
    totalAmountDue() {
      let totalAmount = 0;
      for (let i = 0; i < this.applications.length; i++) {
        totalAmount = totalAmount + this.getApplicationAmountDue(this.applications[i]);
      }
      return this.formatAmount(totalAmount);
    },
    getApplicationAmountDue(application) {
      let amount = 0;
      if (!application.applicationFeeMet) {
        let fee = parseFloat(application.applicationFeeCategory);
        let paid = parseFloat(application.applicationFee);
        if (!isNaN(fee) && !isNaN(paid) && (fee - paid) > 0) {
          amount = fee - paid;
        }
      }
      return amount;
    },
    getSession(admID, prosArea, appEntDt) {
      return this.intents.filter(
          (intent) =>
              intent.admId === admID &&
              intent.processingArea === prosArea &&
              intent.applEnteredDate.substring(0, 10) === appEntDt
      )[0].session;
    },
    formatAmount(a) {
      return parseFloat(a).toFixed(2);
    },
  },
};
</script>

<style scoped>

>>> .dueDate {
  width: 25%;
}

>>> .item {
  width: 50%;
}

>>> .fee_total {
  width: 65%;
  margin-right: 0px;
  margin-left: auto;
}

>>> table.fee_total td {
  border: solid 1px;
  text-align: right;
  padding-right: 5px;
}
</style>