<template>
  <b-container fluid="true">
    <page-load-overlay :show="showOverlay">
      <h6>Previous Institution(s)</h6>
      <p>Please list all institutions attended since last attending UBC. This should include any institutions where you
         are currently registered or plan to register prior to attending UBC.
      </p>
      <b-alert :show="isError" variant="danger" dismissible>
        {{ savingError }}
      </b-alert>
      <re-admission-previous-institution-form v-for="(institution, index) in previousInstitutions"
                                            :value="previousInstitutions[index]"
                                            :index="index"
                                            :key="institution.id"
                                            @input="updateInstitution($event)"
                                            @remove="removeInstitution($event)"
      />

      <div class="btnGroup">
        <b-button
          variant="primary"
          class="btn-size"
          @click="addInstitution">
        {{ previousInstitutions.length > 0 ? 'Add another institution' : 'Add institution' }}
        </b-button>
      </div>
    </page-load-overlay>
  </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import ReAdmissionPreviousInstitutionForm from './ReAdmissionPreviousInstitutionForm.vue';
import PageLoadOverlay from './PageLoadOverlay.vue';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import Multiselect from 'vue-multiselect';
import useVuelidate from "@vuelidate/core";
import _random from 'lodash/random';

export default {
  name: "ReAdmissionPreviousInstitutions",
  components: {
    ReAdmissionPreviousInstitutionForm,
    ChevronRight,
    ChevronDown,
    Multiselect,
    PageLoadOverlay,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("reAdmission", ['previousInstitutions', 'isBusy', 'savingError']),
    isError() {
      return this.savingError != "";
    },
    showOverlay() {
      return this.isBusy;
    },
  },
  setup: () => ({v$: useVuelidate()}),
  created() {
    this.$store.dispatch("reAdmission/loadPreviousInstitutions" );
  },
  methods: {
    addInstitution() {
      let payload = {
        country: "",
        province: "",
        institution: "",
        startDate: "",
        endDate: "",
        isOnLetterOfPermission: "",
        isRequiredToWithdraw: "",
        id: this.generateRandomId(),
      };
      this.$store.commit('reAdmission/addNewPreviousInstitution', payload);
    },
    generateRandomId() {
      return _random(100, 9999);
    },
    removeInstitution(id) {
      this.$store.commit('reAdmission/removePreviousInstitution', id);
    },
    updateInstitution(payload) {
      this.$store.commit('reAdmission/updatePreviousInstitution', payload);
    },
    formValidation() {      
      this.v$.$touch();
      if (this.v$.$dirty && this.v$.$error) {
        return false;
      }
      this.$store.commit('reAdmission/setIsBusy', true);
      this.$store.dispatch("reAdmission/savePreviousInstitutions", this.prepareInput())
          .then(() => {
            this.$store.commit('reAdmission/setIsBusy', false);
            if (!this.savingError){
              this.$emit("forceToNext");
            }
          });
    },
    prepareInput(){
      let result = [];
      for (const obj of this.previousInstitutions) {
          result.push({
            institutionCode: obj.institution.institutionCode,
            description: obj.institution.institutionDescription,
            startDate: obj.startDate,
            endDate: obj.endDate,
            isOnLetterOfPermission: obj.isOnLetterOfPermission,
            isRequiredToWithdraw: obj.isRequiredToWithdraw,
          });
      }
      return {previousInstitutions: result};
    },
    save() {
      return true;
    },
  },
}
</script>

<style scoped>
>>> .btnGroup {
  margin-top: 20px;
  margin-bottom: 6px;
}

>>> .listRow {
  margin-top: 6px;
  margin-bottom: 6px;
}

</style>