<template>
<p>
  Please clear your browser history/cache and come back in 3 hours to allow your first payment attempt to resolve itself. If the issue persists for more than 24 hours, contact ASC support at <a href="mailto:admissions.tech@ubc.ca">admissions.tech@ubc.ca</a>
</p>
</template>
<script>

export default {
  name: "PaymentOutstandingComponent",
}
</script>